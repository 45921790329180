import React from "react";
import { Dropdown } from "react-bootstrap";
import { ReactComponent as RightColor } from "../assets/img/right-color.svg";
import { useTranslation } from "react-i18next";
import AuthStorage from "helper/AuthStorage";
import STORAGEKEY from "config/APP/app.config";

const DropdownMenu = ({ value, onSelect }) => {
  const { i18n } = useTranslation();
  return (
    <Dropdown onSelect={onSelect}>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        {value}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          className={`${value === "Deutsch" && "selected"}`}
          eventKey="Deutsch"
          onClick={() => {
            AuthStorage.setStorageJsonData(STORAGEKEY.lang, "de-DE");
            i18n.changeLanguage("de-DE");
          }}
        >
          Deutsch{value === "Deutsch" && <RightColor />}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownMenu;
