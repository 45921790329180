import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import { getHttpOptions } from "../../helper/API/ApiData";
import AuthStorage from "../../helper/AuthStorage";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { loginUserAPI } from "redux/features/authentication/authSlice";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider,
  OAuthProvider,
  fetchSignInMethodsForEmail,
  signInWithCredential,
} from "firebase/auth";
import { app } from "helper/fireBase";
import { ReactComponent as EmailIcon } from "../../assets/img/email_icon.svg";
import { ReactComponent as EmailIconBlack } from "../../assets/img/email_icon_black.svg";
import { ReactComponent as LockIcon } from "../../assets/img/lock.svg";
import { ReactComponent as LockIconBlack } from "../../assets/img/lock_black.svg";
import { ReactComponent as EyeOpenIcon } from "../../assets/img/eye-open-svgrepo-com.svg";
import { ReactComponent as EyeClosedIcon } from "../../assets/img/eye-closed-svgrepo-com.svg";
import DropdownMenu from "common/Dropdown";
import Error from "common/Error";
import OutlineButton from "common/OutlineButton";
import { Link } from "react-router-dom";
import AuthTextBoxWrapper from "pages/componets/authTextBoxWrapper";
import ContinueButton from "pages/componets/continueButton";
import STORAGEKEY from "config/APP/app.config";
import { unwrapResult } from "@reduxjs/toolkit";
import { appSettings } from "helper/appSettings";
import axios from "axios";
import HelpModal from "common/modals/HelpModal";
import ReactGA from "react-ga4";

const Login = () => {
  const [selectCompany, setSelectCompany] = useState(false);
  const [businessData, setBusinessData] = useState([]);
  const [authToken, setAuthToken] = useState(undefined);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [language, setLanguage] = useState("English");
  const [showPassword, setShowPassword] = useState(false);
  const [helpModal, setHelpModal] = React.useState(false);
  const urlEmail = new URLSearchParams(window.location.search);
  const email = urlEmail.get("email");
  const token = urlEmail.get("joinToken");
  const providers = {
    google: new GoogleAuthProvider(),
    facebook: new FacebookAuthProvider(),
    twitter: new TwitterAuthProvider(),
    apple: new OAuthProvider("apple.com"),
    microsoft: new OAuthProvider("microsoft.com"),
  };
  const LoginSchema = yup.object({
    email: yup.string().email().required(t("SignIn.Validation.email")),
    password: !email && yup.string().required(t("SignIn.Validation.password")),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(LoginSchema),
  });
  const auth = getAuth(app);

  useEffect(() => {
    reset({
      email: email,
    });
  }, [email]);

  useEffect(() => {
    if (AuthStorage.getStorageData(STORAGEKEY.lang)) {
      if (AuthStorage.getStorageData(STORAGEKEY.lang) === "en-us") {
        setLanguage("English");
      } else if (AuthStorage.getStorageData(STORAGEKEY.lang) === "de-DE") {
        setLanguage("Deutsch");
      }
    }
  }, [AuthStorage.getStorageData(STORAGEKEY.lang)]);

  const signInWithGoogle = () => {
    signInWithPopup(auth, providers?.google)
      .then((result) => {
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        const idToken = result._tokenResponse.idToken;
        // const userEmail = result.user?.email;
        // const clientData = { email: userEmail, password: idToken };
        const clientData = { idToken };
        dispatch(loginUserAPI([clientData, token, navigate]))
          .then(unwrapResult)
          .then((res) => {
            if (res?.response?.businesses && res?.response?.businesses.length) {
              setAuthToken(res?.response?.token);
              setBusinessData(res?.response?.businesses);
              setSelectCompany(true);
            } else {
              completeSignin();
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      })
      .catch((error) => {
        const credential = GoogleAuthProvider.credentialFromError(error);
        fetchSignInMethodsForEmail(auth, error.customData.email)
          .then((res) => {
            signInWithCredential(auth, credential)
              .then((user) => {
                user.linkWithCredential(credential);
              })
              .catch((error) => {
                toast.error(t("Use different Email."), {
                  closeOnClick: true,
                  pauseOnHover: true,
                });
              });
          })
          .catch((err) => {
            console.log("err", err);
          });
      });
  };

  const signInWithMicrosoft = () => {
    signInWithPopup(auth, providers?.microsoft)
      .then((result) => {
        const idToken = result._tokenResponse.idToken;
        // const userEmail = result?.user?.email;
        // const clientData = { email: userEmail, password: idToken };
        const clientData = { idToken };
        dispatch(loginUserAPI([clientData, token, navigate]))
          .then(unwrapResult)
          .then((res) => {
            if (res?.response?.businesses) {
              setAuthToken(res?.response?.token);
              setBusinessData(res?.response?.businesses);
              setSelectCompany(true);
            } else {
              completeSignin();
            }
          });
      })
      .catch(async (error) => {
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // const email = error.customData.email;
        const credential = OAuthProvider.credentialFromError(error);
        fetchSignInMethodsForEmail(auth, error.customData.email)
          .then((res) => {
            signInWithCredential(auth, credential)
              .then((user) => {
                user.linkWithCredential(credential);
              })
              .catch((error) => {
                toast.error(t("Use different Email."), {
                  closeOnClick: true,
                  pauseOnHover: true,
                });
                console.log(error);
              });
          })
          .catch((err) => {
            console.log("err", err);
          });
      });
  };

  const signWithApple = () => {
    signInWithPopup(auth, providers?.apple)
      .then((result) => {
        const credential = OAuthProvider.credentialFromResult(result);
        const idToken = result._tokenResponse.idToken;
        const userEmail = result.user?.email;
        const clientData = { email: userEmail, password: idToken };
        dispatch(loginUserAPI([clientData, token, navigate]))
          .then(unwrapResult)
          .then((res) => {
            if (res?.response?.businesses) {
              setAuthToken(res?.response?.token);
              setBusinessData(res?.response?.businesses);
              setSelectCompany(true);
            } else {
              completeSignin();
            }
          });
      })
      .catch((error) => {
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // const email = error.customData.email;
        const credential = OAuthProvider.credentialFromError(error);
        fetchSignInMethodsForEmail(auth, error.customData.email)
          .then((res) => {
            signInWithCredential(auth, credential)
              .then((user) => {
                user.linkWithCredential(credential);
              })
              .catch((error) => {
                toast.error(t("Use different Email."), {
                  closeOnClick: true,
                  pauseOnHover: true,
                });
                console.log(error);
              });
          })
          .catch((err) => {
            console.log("err", err);
          });
      });
  };

  const signInWithTwitter = () => {
    signInWithPopup(auth, providers?.twitter)
      .then((result) => {
        const credential = TwitterAuthProvider.credentialFromResult(result);
        const idToken = result._tokenResponse.idToken;
        const userEmail = result.user?.email;
        const clientData = { email: userEmail, password: idToken };
        dispatch(loginUserAPI([clientData, token, navigate]))
          .then(unwrapResult)
          .then((res) => {
            if (res?.response?.businesses) {
              setAuthToken(res?.response?.token);
              setBusinessData(res?.response?.businesses);
              setSelectCompany(true);
            } else {
              completeSignin();
            }
          });
      })
      .catch((error) => {
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // const email = error.customData.email;
        const credential = TwitterAuthProvider.credentialFromError(error);
        fetchSignInMethodsForEmail(auth, error.customData.email)
          .then((res) => {
            signInWithCredential(auth, credential)
              .then((user) => {
                user.linkWithCredential(credential);
              })
              .catch((error) => {
                toast.error(t("Use different Email."), {
                  closeOnClick: true,
                  pauseOnHover: true,
                });
                console.log(error);
              });
          })
          .catch((err) => {
            console.log("err", err);
          });
      });
  };

  const signInWithFacebook = () => {
    signInWithPopup(auth, providers?.facebook)
      .then((result) => {
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const idToken = result._tokenResponse.idToken;
        const userEmail = result.user?.email;
        const clientData = { email: userEmail, password: idToken };
        dispatch(loginUserAPI([clientData, token, navigate]))
          .then(unwrapResult)
          .then((res) => {
            if (res?.response?.businesses) {
              setAuthToken(res?.response?.token);
              setBusinessData(res?.response?.businesses);
              setSelectCompany(true);
            } else {
              completeSignin();
            }
          });
      })
      .catch((error) => {
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // const email = error.customData.email;
        const credential = FacebookAuthProvider.credentialFromError(error);
        fetchSignInMethodsForEmail(auth, error.customData.email)
          .then((res) => {
            signInWithCredential(auth, credential)
              .then((user) => {
                user.linkWithCredential(credential);
              })
              .catch((error) => {
                toast.error(t("Use different Email."), {
                  closeOnClick: true,
                  pauseOnHover: true,
                });
                console.log(error);
              });
          })
          .catch((err) => {
            console.log("err", err);
          });
        console.log("error", error);
      });
  };

  const handleClose = () => {
    setSelectCompany(false);
    AuthStorage.deauthenticateUser();
  };

  const toggleShowPassword = () => {
    showPassword ? setShowPassword(false) : setShowPassword(true);
  };
  const completeSignin = () => {
    ReactGA.event({
      action: "Signed In",
      category: "Sign in",
    });
    window.location = appSettings.PORTAL_APP_URL;
  };

  return (
    <>
      <div className="signup-bg">
        <div className="signup-header">
          <Link to="/">
            <img
              src="./assets/img/new-logo.png"
              alt="no image find"
              width="100x"
            />
          </Link>
          {/* <DropdownMenu onSelect={(e) => setLanguage(e)} value={language} /> */}
          <OutlineButton
            btnTxt={t("SignIn.btn_text_sign_up")}
            onClick={() => {
              AuthStorage.deleteKey(STORAGEKEY.signupStep);
              navigate("/signup");
            }}
          />
        </div>
        <div
          className="bg-img"
          style={{ display: "flex", flexDirection: "row", padding: "20px" }}
        >
          <div className="login_container">
            <Container>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <div className="signup-popup">
                  <div className="signup-body">
                    <h1>{t("SignIn.signIn")}</h1>
                    <div className="sign-up-with">
                      <div className="login_socilmedia">
                        {/* <button
                          type="button"
                          className="google"
                          onClick={signInWithGoogle}
                          style={{ marginRight: "50px" }}
                        >
                          <img
                            src="./assets/img/google.png"
                            alt=""
                            style={{ cursor: "pointer" }}
                          />
                          <h6>
                            {t("SignIn.sign_in_with")}{" "}
                            <span>{t("SignIn.google")}</span>
                          </h6>
                        </button> */}
                        <div style={{ display: "flex", gap: "20px" }}>
                          {/* <img
                            src="./assets/img/office-365.svg"
                            alt="microsoft"
                            style={{ cursor: "pointer" }}
                            onClick={signInWithMicrosoft}
                          /> */}
                          {/* <img
                            src="./assets/img/apple.svg"
                            alt="apple"
                            width="38px"
                            style={{ cursor: "pointer" }}
                            onClick={signWithApple}
                          />
                          <img
                            src="./assets/img/twitter.svg"
                            alt="twitter"
                            onClick={signInWithTwitter}
                            style={{ cursor: "pointer" }}
                          />
                          <img
                            src="./assets/img/facebook.svg"
                            alt="facebook"
                            style={{ cursor: "pointer" }}
                            onClick={signInWithFacebook}
                          /> */}
                        </div>
                      </div>
                    </div>
                    <div className="Signup-form">
                      <div style={{ marginTop: "16px" }} />
                      <AuthTextBoxWrapper
                        placeholderImage={<EmailIcon />}
                        focusPlaceholderImage={<EmailIconBlack />}
                      >
                        <input
                          type="text"
                          placeholder={t("SignIn.Placeholder.email")}
                          defaultValue={email}
                          name="email"
                          {...register("email")}
                          autoFocus
                        />
                      </AuthTextBoxWrapper>
                      {errors?.email && (
                        <Error message={errors?.email?.message} />
                      )}
                      <div style={{ marginTop: "16px" }} />
                      <AuthTextBoxWrapper
                        placeholderImage={<LockIcon />}
                        focusPlaceholderImage={<LockIconBlack />}
                        eye={
                          showPassword ? (
                            <EyeOpenIcon onClick={toggleShowPassword} />
                          ) : (
                            <EyeClosedIcon onClick={toggleShowPassword} />
                          )
                        }
                      >
                        <input
                          type={showPassword ? "text" : "password"}
                          placeholder={t("SignIn.Placeholder.password")}
                          name="password"
                          {...register("password")}
                        />
                      </AuthTextBoxWrapper>
                      {errors?.password && (
                        <Error message={errors?.password?.message} />
                      )}
                      <Link to="/forgot-password" className="forgotPassword">
                        {t("Login.forgotPassword")}
                      </Link>
                      <ContinueButton
                        onSubmit={handleSubmit(async (data, e) => {
                          dispatch(loginUserAPI([data, token, navigate]))
                            .then(unwrapResult)
                            .then((res) => {
                              if (
                                res &&
                                res.response &&
                                res.response.businesses &&
                                res.response.token
                              ) {
                                setAuthToken(res.response.token);
                                setBusinessData(res.response.businesses);
                                setSelectCompany(true);
                              } else if (
                                res &&
                                res.response &&
                                res.response.token &&
                                res.response.user
                              ) {
                                completeSignin();
                              }
                            })
                            .catch((err) => {
                              console.log("err", err);
                            });
                        })}
                        text={t("SignIn.btn_text_sign_in")}
                        arrowVisible={false}
                      />
                      <p
                        className="supportLink"
                        onClick={() => setHelpModal(true)}
                      >
                        {"Need support?"}
                      </p>
                    </div>
                  </div>
                </div>
              </form>
            </Container>
            <div className="login_side">
              <img
                width="320"
                src="./assets/img/login-side-image.png"
                alt=""
                style={{ marginBottom: "20px" }}
              />
              <div
                className="login_text"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <h2 className="header">Enrich Loyalty With Gamification!</h2>
                <h6
                  className="subheader"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <ul style={{ textAlign: "start", width: "300px" }}>
                    <li>Create your branded game</li>
                    <li>Launch a campaign within minutes</li>
                    <li>Stay informed with real-time results</li>
                  </ul>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={selectCompany}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="create-coupons-modals add-customer select_company"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontFamily: "system - ui" }}
          >
            Select Company
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ rowGap: "24px" }}>
            {businessData.map((ele) => (
              <Col lg={6} md={6} key={ele.id}>
                <div
                  className="company_card"
                  onClick={() => {
                    AuthStorage.setToken(authToken);
                    axios
                      .get(
                        `${appSettings.API_BASE_URL}user-service/users/select-business-for-user/${ele.id}`,
                        getHttpOptions()
                      )
                      .then((res) => {
                        AuthStorage.setToken(res?.data?.response?.token);
                        AuthStorage.setStorageData(
                          STORAGEKEY.refreshToken,
                          res.headers[STORAGEKEY.refreshToken]
                        );
                        completeSignin();
                      })
                      .catch((err) => {
                        console.log("err", err.response);
                      });
                  }}
                >
                  <img src="./assets/img/new-logo.png" width="50%" alt="" />
                  <h4>{ele.name}</h4>
                </div>
              </Col>
            ))}
          </Row>
        </Modal.Body>
      </Modal>
      <HelpModal show={helpModal} onHide={() => setHelpModal(false)} />
    </>
  );
};

export default Login;
