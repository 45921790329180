import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import AuthStorage from "helper/AuthStorage";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { signUpUserAPI } from "redux/features/authentication/authSlice";
import { ReactComponent as EmailIcon } from "../../assets/img/email_icon.svg";
import { ReactComponent as EmailIconActive } from "../../assets/img/email_icon_black.svg";
import ReactGA from "react-ga4";
import SignupCommon from "./SignUpCommon";
import {
  FacebookAuthProvider,
  fetchSignInMethodsForEmail,
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithCredential,
  signInWithPopup,
  TwitterAuthProvider,
} from "firebase/auth";
import { app } from "helper/fireBase";
import Error from "common/Error";
import ContinueButton from "pages/componets/continueButton";
import AuthTextBoxWrapper from "pages/componets/authTextBoxWrapper";
import axios from "axios";
import STORAGEKEY from "config/APP/app.config";
import { appSettings } from "helper/appSettings";
import { ApiGet } from "helper/API/ApiData";

const Emailpage = ({ onNext }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [btnHover, setBtnHover] = useState(false);
  const [termsPrivacyModal, setTermsPrivacyModal] = useState(false);
  const [terms, setTerms] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [socialTog, setSocialTog] = useState("");
  const [privacyData, setPrivacyData] = useState([]);
  const [termsAndConditionsData, setTermsAndConditionsData] = useState([]);

  const urlEmail = new URLSearchParams(window.location.search);
  const email = urlEmail.get("email");
  const auth = getAuth(app);
  const providers = {
    google: new GoogleAuthProvider(),
    facebook: new FacebookAuthProvider(),
    twitter: new TwitterAuthProvider(),
    apple: new OAuthProvider("apple.com"),
    microsoft: new OAuthProvider("microsoft.com"),
  };

  const { t } = useTranslation();
  const SignupSchema = yup.object({
    email: yup.string().email().required(t("SignUp.Errors.email")),
  });

  const SignupSchema1 = yup.object({
    termsCondition: yup.boolean().oneOf([true], "😀😀"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SignupSchema),
  });

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
  } = useForm({
    defaultValues: { agree: false },
    resolver: yupResolver(SignupSchema1),
  });

  useEffect(() => {
    reset({
      email: email,
    });
  }, [email]);

  const nextRoute = () => {
    if (email) {
      onNext(3);
    } else onNext(2);
  };

  const emailUserAPI = async (data) => {
    localStorage.setItem(STORAGEKEY.signupStep, [1]);
    // localStorage.setItem(STORAGEKEY.userData, data[0]);
    // AuthStorage.setStorageJsonData(STORAGEKEY.signupStep, [1]);
    AuthStorage.setStorageJsonData(STORAGEKEY.userData, data[0]);
    const email = data[0].email;
    axios
      .post(`${appSettings.API_BASE_URL}user-service/auth/check-email`, {
        email,
      })
      .then((res) => {
        nextRoute();
        setBtnHover(false);
        AuthStorage.setToken(res?.data?.response?.token);
        AuthStorage.setStorageData(
          STORAGEKEY.refreshToken,
          res.headers[STORAGEKEY.refreshToken]
        );
        return res;
      })
      .catch((err) => {
        console.log("err", err);
        setBtnHover(true);
      });
  };

  const termsModal = (fleg) => {
    setTermsPrivacyModal(true);
    setSocialTog(fleg);
  };

  useEffect(() => {
    ApiGet(
      `${appSettings?.API_BASE_URL}/info-service/api/static_info/privacyPortal`
    ).then((res) => {
      setPrivacyData(res?.response?.json_body?.data ?? []);
    });
    ApiGet(
      `${appSettings?.API_BASE_URL}/info-service/api/static_info/termsAndConditions`
    ).then((res) => {
      setTermsAndConditionsData(res?.response?.body ?? []);
    });
  }, []);

  const handleCloseModal = () => {
    setTerms(false);
    setPrivacy(false);
  };

  const signInWithGoogle = () => {
    signInWithPopup(auth, providers?.google)
      .then((result) => {
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        const idToken = result._tokenResponse.idToken;
        const userEmail = result.user?.email;
        const clientData = {
          email: userEmail,
          password: idToken,
          job_title: "Company",
        };
        dispatch(signUpUserAPI([clientData, navigate]));
      })
      .catch((error) => {
        // Handle Errors here.
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        fetchSignInMethodsForEmail(auth, error.customData.email)
          .then((res) => {
            console.log("res", res);
            signInWithCredential(auth, credential)
              .then((user) => {
                user.linkWithCredential(credential);
              })
              .catch((error) => {
                toast.error(t("Use different Email."), {
                  closeOnClick: true,
                  pauseOnHover: true,
                });
              });
          })
          .catch((err) => {
            console.log("err", err);
          });
      });
  };

  const signInWithFacebook = () => {
    signInWithPopup(auth, providers?.facebook)
      .then((result) => {
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const idToken = result._tokenResponse.idToken;
        const userEmail = result.user?.email;
        const clientData = {
          email: userEmail,
          password: idToken,
          job_title: "Company",
        };
        dispatch(signUpUserAPI([clientData, navigate]));
      })
      .catch((error) => {
        // Handle Errors here.
        // The AuthCredential type that was used.
        const credential = FacebookAuthProvider.credentialFromError(error);
        fetchSignInMethodsForEmail(auth, error.customData.email)
          .then((res) => {
            signInWithCredential(auth, credential)
              .then((user) => {
                user.linkWithCredential(credential);
              })
              .catch((error) => {
                toast.error(t("Use different Email."), {
                  closeOnClick: true,
                  pauseOnHover: true,
                });
                console.log(error);
              });
          })
          .catch((err) => {
            console.log("err", err);
          });
        console.log("error", error);
      });
  };

  const signInWithTwitter = () => {
    signInWithPopup(auth, providers?.twitter)
      .then((result) => {
        const credential = TwitterAuthProvider.credentialFromResult(result);
        const idToken = result._tokenResponse.idToken;
        const userEmail = result.user?.email;
        const clientData = {
          email: userEmail,
          password: idToken,
          job_title: "Company",
        };
        dispatch(signUpUserAPI([clientData, navigate]));
      })
      .catch((error) => {
        // Handle Errors here.
        // The AuthCredential type that was used.
        const credential = TwitterAuthProvider.credentialFromError(error);
        fetchSignInMethodsForEmail(auth, error.customData.email)
          .then((res) => {
            signInWithCredential(auth, credential)
              .then((user) => {
                user.linkWithCredential(credential);
              })
              .catch((error) => {
                toast.error(t("Use different Email."), {
                  closeOnClick: true,
                  pauseOnHover: true,
                });
                console.log(error);
              });
          })
          .catch((err) => {
            console.log("err", err);
          });
        console.log("error", error);
      });
  };

  const signWithApple = () => {
    signInWithPopup(auth, providers?.apple)
      .then((result) => {
        const credential = OAuthProvider.credentialFromResult(result);
        const idToken = result._tokenResponse.idToken;
        const userEmail = result.user?.email;
        const clientData = {
          email: userEmail,
          password: idToken,
          job_title: "Company",
        };
        dispatch(signUpUserAPI([clientData, navigate]));
      })
      .catch((error) => {
        // Handle Errors here.
        // The credential that was used.
        const credential = OAuthProvider.credentialFromError(error);
        fetchSignInMethodsForEmail(auth, error.customData.email)
          .then((res) => {
            signInWithCredential(auth, credential)
              .then((user) => {
                user.linkWithCredential(credential);
              })
              .catch((error) => {
                toast.error(t("Use different Email."), {
                  closeOnClick: true,
                  pauseOnHover: true,
                });
                console.log(error);
              });
          })
          .catch((err) => {
            console.log("err", err);
          });
      });
  };

  const signInWithMicrosoft = () => {
    signInWithPopup(auth, providers?.microsoft)
      .then((result) => {
        // const credential = OAuthProvider.credentialFromResult(result);
        const idToken = result._tokenResponse.idToken;
        const userEmail = result?.user?.email;
        const clientData = {
          email: userEmail,
          password: idToken,
          job_title: "Company",
        };
        dispatch(signUpUserAPI([clientData, navigate]));
      })
      .catch(async (error) => {
        // Handle Errors here.
        // The AuthCredential type that was used.
        const credential = OAuthProvider.credentialFromError(error);
        fetchSignInMethodsForEmail(auth, error.customData.email)
          .then((res) => {
            signInWithCredential(auth, credential)
              .then((user) => {
                user.linkWithCredential(credential);
              })
              .catch((error) => {
                toast.error(t("Use different Email."), {
                  closeOnClick: true,
                  pauseOnHover: true,
                });
                console.log(error);
              });
          })
          .catch((err) => {
            console.log("err", err);
          });
      });
  };

  return (
    <SignupCommon>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <h2 className="main-heading">{t("createProfile.heder")}</h2>
        <h3>{t("SignIn.start")}</h3>

        <p className="sub-heading">{t("createProfile.text")}</p>

        <AuthTextBoxWrapper
          placeholderImage={<EmailIcon />}
          focusPlaceholderImage={<EmailIconActive />}
        >
          <input
            type="text"
            placeholder={t("createProfile.work_email_placeholder")}
            name="email"
            defaultValue={email}
            {...register("email")}
            autoFocus
          />
        </AuthTextBoxWrapper>

        {btnHover && <Error message={"email ID already used"} />}
        {errors?.email && !btnHover && (
          <Error message={errors?.email?.message} />
        )}

        <ContinueButton
          onSubmit={handleSubmit(async (data) => {
            ReactGA.event({
              action: "Started signing up",
              category: "Sign up",
              value: "",
            });
            await emailUserAPI([data, navigate]);
          })}
        />
      </form>
      <div className="sign-up-with sign-up-icons">
        {/* <h6 className="sign-up-with-heading">
          {t("createProfile.sign_up_with")} {t("createProfile.google")}
        </h6> */}

        <div className="login_socilmedia">
          {/* <button
            type="button"
            className="google"
            onClick={() => termsModal("google")}
            style={{ cursor: "pointer", marginRight: "50px" }}
          >
            <img src="./assets/img/google.png" alt="" />
            <h6>
              {t("createProfile.sign_up_with")}{" "}
              <span>{t("createProfile.google")}</span>
            </h6>
          </button> */}
          <div style={{ display: "flex", gap: "20px" }}>
            {/* <img
              src="./assets/img/office-365.svg"
              alt="microsoft"
              style={{ cursor: "pointer" }}
              onClick={() => termsModal("microsoft")}
            /> */}
            {/* <img
              src="./assets/img/apple.svg"
              alt="apple"
              width="38px"
              style={{ cursor: "pointer" }}
              onClick={() => termsModal("apple")}
            />
            <img
              src="./assets/img/twitter.svg"
              alt="twitter"
              onClick={() => termsModal("twitter")}
              style={{ cursor: "pointer" }}
            />
            <img
              src="./assets/img/facebook.svg"
              alt="facebook"
              style={{ cursor: "pointer" }}
              onClick={() => termsModal("facebook")}
            /> */}
          </div>
        </div>
        {/* </div> */}
      </div>
      <Modal
        show={termsPrivacyModal}
        onHide={() => setTermsPrivacyModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="create-coupons-modals add-customer terms_privacy_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontFamily: "system - ui" }}
          >
            Terms & Privacy Policy
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "500px", overflowY: "auto" }}>
          <form onSubmit={(e) => e.preventDefault()}>
            {errors?.password && <Error message={errors?.password?.message} />}
            <div className="check-box-password-main">
              <input
                type="checkbox"
                name="termsCondition"
                className="check-box-password"
                {...register1("termsCondition")}
              />
              <label>
                {t("setPassword.i_agree_to_boomio")}&nbsp;
                <span
                  style={{ borderBottom: "1px solid #000" }}
                  onClick={() => {
                    setTerms(true);
                    setPrivacy(false);
                  }}
                >
                  {t("setPassword.terms")}
                </span>
                &nbsp;
                {t("setPassword.and")}&nbsp;
                <span
                  style={{ borderBottom: "1px solid #000" }}
                  onClick={() => {
                    setTerms(false);
                    setPrivacy(true);
                  }}
                >
                  {t("setPassword.privacy_policy")}
                </span>
              </label>
            </div>
            {errors1?.termsCondition && (
              <Error message={errors1?.termsCondition?.message} />
            )}
            <div className="check-box-password-main-bottom check-box-password-main">
              <input
                type="checkbox"
                name="agree"
                className="check-box-password"
                {...register1("agree")}
              />
              <label>{t("setPassword.agree_with_boomio_updates")}</label>
            </div>
            {
              <div
                style={{ width: "250px" }}
                onMouseEnter={() => setBtnHover(true)}
                onMouseLeave={() => setBtnHover(false)}
              >
                <ContinueButton
                  onSubmit={handleSubmit1((data) => {
                    if (socialTog === "google") {
                      signInWithGoogle();
                    } else if (socialTog === "microsoft") {
                      signInWithMicrosoft();
                    } else if (socialTog === "apple") {
                      signWithApple();
                    } else if (socialTog === "twitter") {
                      signInWithTwitter();
                    } else if (socialTog === "facebook") {
                      signInWithFacebook();
                    }
                  })}
                />
              </div>
            }
          </form>
        </Modal.Body>
      </Modal>
      {privacy && (
        <Modal
          show={privacy}
          onHide={handleCloseModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="create-coupons-modals add-customer"
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="contained-modal-title-vcenter"
              style={{ fontFamily: "system - ui" }}
            >
              {t("setPassword.privacy_policy")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ height: "100%", overflowY: "auto" }}>
            <Row>
              <Col lg={2}>
                {privacyData?.map((item, index) => {
                  return (
                    <p>
                      {index + 1}. {item?.chapter}
                    </p>
                  );
                })}
              </Col>
              <Col lg={10}>
                <div className="privacy-policy">
                  {privacyData.map((item) => (
                    <div className="some-title">
                      <h1
                        dangerouslySetInnerHTML={{ __html: item?.chapter }}
                      ></h1>
                      <p
                        dangerouslySetInnerHTML={{ __html: item?.chapter_data }}
                      ></p>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      )}
      {terms && (
        <Modal
          show={terms}
          onHide={handleCloseModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="create-coupons-modals add-customer"
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="contained-modal-title-vcenter"
              style={{ fontFamily: "system - ui" }}
            >
              {t("setPassword.terms")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ height: "100%", overflowY: "auto" }}>
            <Row>
              <div
                dangerouslySetInnerHTML={{ __html: termsAndConditionsData }}
              />
            </Row>
          </Modal.Body>
        </Modal>
      )}
    </SignupCommon>
  );
};
export default Emailpage;
