import DropdownMenu from "common/Dropdown";
import OutlineButton from "common/OutlineButton";
import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import "../../App.css";
import { useTranslation } from "react-i18next";
import AuthTextBoxWrapper from "pages/componets/authTextBoxWrapper";
import ContinueButton from "pages/componets/continueButton";
import AuthStorage from "helper/AuthStorage";
import STORAGEKEY from "config/APP/app.config";
import { toast } from "react-toastify";
import { Loader } from "../signup/Loader";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Error from "common/Error";
import { ApiPostNoAuth } from "../../helper/API/ApiData";
import { ReactComponent as LockIcon } from "../../assets/img/lock.svg";
import { ReactComponent as LockIconBlack } from "../../assets/img/lock_black.svg";
import { appSettings } from "helper/appSettings";

const AuthSetPassword = () => {
  const [isLoder, setIsLoder] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  let [searchParams] = useSearchParams();

  const passwordToken = searchParams.get(STORAGEKEY.token);

  const SignupSchema = yup.object({
    password: yup
      .string()
      .required("Password is mendatory")
      .min(8, "Must be more than 8 characters"),
    passwordConfirm: yup
      .string()
      .required("Password is mendatory")
      .oneOf([yup.ref("password")], "Passwords does not match"),
  });

  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SignupSchema),
  });

  const [language, setLanguage] = useState("English");

  useEffect(() => {
    if (passwordToken === null || passwordToken === undefined) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (AuthStorage.getStorageData(STORAGEKEY.lang)) {
      if (AuthStorage.getStorageData(STORAGEKEY.lang) === "en-us") {
        setLanguage("English");
      } else if (AuthStorage.getStorageData(STORAGEKEY.lang) === "de-DE") {
        setLanguage("Deutsch");
      }
    }
  }, [AuthStorage.getStorageData(STORAGEKEY.lang)]);

  const handleForgotPassword = () => {
    setIsLoder(true);
    const body = {
      password: getValues("password"),
      passwordConfirm: getValues("passwordConfirm"),
    };
    ApiPostNoAuth(
      `${appSettings?.API_BASE_URL}user-service/auth/reset-password/${passwordToken}`,
      body
    )
      .then((res) => {
        setIsLoder(false);
        toast.success(res.message, {
          closeOnClick: true,
          pauseOnHover: true,
        });
        navigate("/");
        return res;
      })
      .catch((err) => {
        toast.error(t("SignUp.Toast.error", err), {
          closeOnClick: true,
          pauseOnHover: true,
        });
        setIsLoder(false);
        return err;
      });
  };

  return (
    <div className="signup-bg">
      <div className="signup-header">
        <Link to="/">
          <img
            src="./assets/img/new-logo.png"
            alt="no image find"
            width="100x"
          />
        </Link>
        <div className="d-flex align-items-center">
          {/* <DropdownMenu onSelect={(e) => setLanguage(e)} value={language} /> */}
          <OutlineButton
            btnTxt="Sign up"
            onClick={() => {
              navigate("/signup");
            }}
          />
        </div>
      </div>
      <div className="bg-img">
        <Container>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="signup-popup">
              <div className="signup-body">
                <h1 className="password-heading">{t("Set password")}</h1>
                <p className="password-desc">{t("forgotPassword.text")}</p>
                <div className="Signup-form mt-2">
                  <div style={{ marginTop: "16px" }} />
                  <AuthTextBoxWrapper
                    placeholderImage={<LockIcon />}
                    focusPlaceholderImage={<LockIconBlack />}
                  >
                    <input
                      type="password"
                      placeholder={t("password")}
                      name="password"
                      {...register("password")}
                      autoFocus
                      className={` ${errors.password ? "is-invalid" : ""}`}
                    />
                  </AuthTextBoxWrapper>
                  {errors?.password && (
                    <Error message={errors?.password?.message} />
                  )}

                  <div style={{ marginTop: "16px" }} />
                  <AuthTextBoxWrapper
                    placeholderImage={<LockIcon />}
                    focusPlaceholderImage={<LockIconBlack />}
                  >
                    <input
                      type="password"
                      placeholder={t("SignUp.Placeholder.confirmPassword")}
                      name="passwordConfirm"
                      {...register("passwordConfirm")}
                      autoFocus
                      className={`${
                        errors.passwordConfirm ? "is-invalid" : ""
                      }`}
                    />
                  </AuthTextBoxWrapper>
                  {errors?.passwordConfirm && (
                    <Error message={errors?.passwordConfirm?.message} />
                  )}

                  <ContinueButton
                    onSubmit={handleSubmit((data) => {
                      handleForgotPassword(data);
                      navigate("/");
                    })}
                  >
                    {t("forgotPassword.btn_continue")}
                  </ContinueButton>
                </div>

                <Link to="/" className="back-sign">
                  {t("forgotPassword.back_to_sign_in")}
                </Link>
              </div>
            </div>
          </form>
        </Container>
      </div>
      {isLoder && <Loader />}
    </div>
  );
};

export default AuthSetPassword;
