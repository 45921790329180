import React, { useEffect, useState, useRef } from "react";
import { ApiPostNoAuth } from "../../helper/API/ApiData";
import { toast } from "react-toastify";
import AuthStorage from "helper/AuthStorage";
import { useTranslation } from "react-i18next";
import SignupCommon from "./SignUpCommon";
import { appSettings } from "helper/appSettings";
import { useTimer } from "pages/useTimer";
import axios from "axios";
import STORAGEKEY from "config/APP/app.config";
import HelpModal from "common/modals/HelpModal";
import PinField from "react-pin-field";
import "./style.scss";
import cn from "classnames";
import ReactGA from "react-ga4";
import Cookie from "js-cookie";

const VerificationOtp = (otp) => {
  const { minutes, setMinutes, seconds, setSeconds } = useTimer(3, 0);
  const [verify, setVerify] = useState(false);
  const [pin, setPin] = useState("");
  const joinToken = new URLSearchParams(window.location.search);
  const token = joinToken.get("joinToken");
  const [helpModal, setHelpModal] = React.useState(false);
  const [storageData, setStorageData] = useState();
  const { t } = useTranslation();
  const regExp = /\b\d{6}\b/;
  const ref = useRef([]);
  const [initPin, setInitPin] = useState(otp?.otp);
  const [firstOtpSend, setFirstOtpSend] = useState(false);
  useEffect(() => {
    setStorageData(AuthStorage.getStorageJsonData(STORAGEKEY.userData));
  }, []);
  const [isLoder, setIsLoder] = useState(false);

  useEffect(() => {
    if (storageData?.email && !firstOtpSend && !otp?.otp) {
      sendOtp();
      setFirstOtpSend(true);
    }
  }, [storageData]);

  const sendOtp = () => {
    setIsLoder(true);
    let data = storageData;
    const body = {
      email: data?.email,
    };
    ApiPostNoAuth(`${appSettings.API_BASE_URL}user-service/auth/send-otp`, body)
      .then((res) => {
        setIsLoder(false);
        if ("data" in res) {
          setMinutes(res.data?.minutes);
          setSeconds(res.data?.seconds);
        } else {
          setMinutes(3);
          setSeconds(0);
          toast.success(res?.metadata?.res_msg, {
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
        return res;
      })
      .catch((err) => {
        toast.error(t("SignUp.Toast.error", err), {
          closeOnClick: true,
          pauseOnHover: true,
        });
        setIsLoder(false);
        return err;
      });
  };

  const onSubmitLoader = () => {
    setIsLoder(true);
    delete storageData?.agree;
    delete storageData?.termsCondition;
    let data = storageData;
    delete data?.passwordConfirm;
    const body = {
      ...data,
      job_title: "job_title" in data ? data?.job_title : "test",
      originSource: "source1",
      country: "IN",
      language: "EN",
      business: {
        name: data?.job_title,
        address: "test",
        logo: "test",
        description: "test",
        business_type: "test",
      },
    };
    axios
      .post(
        `${appSettings.API_BASE_URL}user-service/auth/portal/register${
          token ? `?joinToken=${token}` : ""
        }`,
        body
      )
      .then((res) => {
        toast.success(t("SignUp.Toast.success"), {
          closeOnClick: true,
          pauseOnHover: true,
        });
        AuthStorage.setToken(res?.data?.response?.token);
        AuthStorage.setStorageData(
          STORAGEKEY.refreshToken,
          res.headers[STORAGEKEY.refreshToken]
        );
        Cookie.set("firstLogin", true, { domain: ".boomio.eu" });
        ReactGA.event({
          action: "Signed up",
          category: "Sign up",
          value: "",
        });
        window.location = appSettings.PORTAL_APP_URL;
      })
      .catch((err) => {
        toast.error(t("SignUp.Toast.error", err), {
          closeOnClick: true,
          pauseOnHover: true,
        });
        setIsLoder(false);
        return err;
      });
  };

  const resendOTP = () => {
    if (!verify) {
      setMinutes();
      setSeconds();
      sendOtp();
    }
  };

  const verifyOtp = () => {
    let data = storageData;
    const body = {
      otp: pin,
      email: data?.email,
    };
    ApiPostNoAuth(
      `${appSettings.API_BASE_URL}user-service/auth/verify-otp`,
      body
    )
      .then((res) => {
        setVerify(true);
        onSubmitLoader();
        return res;
      })
      .catch((err) => {
        setVerify(false);
        const pinInputs = document.querySelectorAll(".pin-field");
        pinInputs.forEach((input) => {
          input.classList.add("shake");
        });
        setTimeout(() => {
          pinInputs.forEach((input) => {
            input.classList.remove("shake");
          });
        }, 400);
        return err;
      });
  };

  const handlePinChange = (value) => {
    setPin(value);
  };

  useEffect(() => {
    if (initPin) {
      ref &&
        ref.current &&
        ref.current.forEach((input, index) => {
          if (index < initPin.length) {
            input.value = initPin[index];
          }
        });
      setPin(initPin);
    }
    if (pin.length === 6) {
      verifyOtp();
    }
  }, [initPin, pin, storageData]);

  return (
    <SignupCommon isLoader={isLoder}>
      <h2 className="main-heading sub-heading-left">
        {t("varificationOtp.heder")}
      </h2>
      <h3>{t("varificationOtp.check")}</h3>
      <p className="sub-heading sub-heading-left">
        We've sent an email to <b> {storageData?.email}.</b> Please verify your
        email address by following the instructions provided in the email. Once
        verified, you'll gain full access to our platform's features and
        benefits.
      </p>
      <div className="pin-field-container">
        <PinField
          onChange={handlePinChange}
          name="otp"
          // disabled={verify}
          className={cn("pin-field", { complete: verify })}
          autoComplete="one-time-password"
          autoFocus
          length={6}
          ref={ref}
        />
      </div>
      <p className="resend">
        {t("varificationOtp.donot_received_email")}{" "}
        <u onClick={() => setHelpModal(true)} style={{ cursor: "pointer" }}>
          {t("varificationOtp.contact_us")}
        </u>
        .
      </p>

      <p className="resend">
        {seconds > 0 || minutes > 0 ? (
          <>
            Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
            {seconds < 10 ? `0${seconds}` : seconds}
          </>
        ) : (
          <span>Didn't recieve code?</span>
        )}
        <br />
        <button
          disabled={seconds > 0 || minutes > 0}
          style={{
            color: seconds > 0 || minutes > 0 ? "#DFE3E8" : "#FF5630",
          }}
          className="resendButton"
          onClick={resendOTP}
        >
          {t("varificationOtp.send_code")}
        </button>
        {"again or find more information in"}{" "}
        <u onClick={() => setHelpModal(true)}>
          {t("varificationOtp.help_center")}
        </u>
      </p>
      <HelpModal show={helpModal} onHide={() => setHelpModal(false)} />
    </SignupCommon>
  );
};
export default VerificationOtp;
